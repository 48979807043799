import React from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import { Layout } from "~/templates/Layout";
import { colors } from "~/utils/colors";
import { breakPoints, siteWidth } from "~/utils/variables";
import { renderHtmlAst } from "~/molecules/BaseBody";
import { InquiryForm } from "~/organisms/InquiryForm";

type Props = {
  data: {
    inquiry: GatsbyTypes.ContentfulInquiry;
  };
};

const Inquiry: React.FC<Props> = ({ data }) => {
  const { inquiry } = data;

  return (
    <>
      <style jsx>{`
        .wrapper {
          background: ${colors.bgGrayGradient};
          padding: 0 0 50px;
          margin: 100px 0 0;
        }
        .container {
          display: flex;
          flex-direction: column;
          margin: 0 auto;
          max-width: ${siteWidth.default};
          padding: 0 30px;
        }
        .title {
          text-align: center;
          margin: 0;
        }
        .content {
          margin: 30px 0 0;
        }
        .image {
          margin: 30px 0 0;
          width: 100%;
        }
        @media (${breakPoints.sp}) {
          .body {
            margin: 0;
          }
          .form {
            margin: 20px 0 0;
          }
          .title {
            font-size: 24px;
          }
        }
        @media (${breakPoints.minPc}) {
          .content {
            display: flex;
            justify-content: center;
          }
          .body {
            margin: 0 30px 0 0;
            width: calc(60% - 30px);
          }
          .form {
            width: 40%;
          }
          .title {
            font-size: 32px;
          }
        }
      `}</style>
      <Layout hideBreadCrumbs noindex>
        <div className="wrapper">
          <div className="container">
            <h2 className="title">{inquiry.title}</h2>
            <div className="content">
              <div className="body">
                {renderHtmlAst(inquiry.body?.childMarkdownRemark?.htmlAst)}
                {inquiry?.image && (
                  <div className="image">
                    <Img fluid={inquiry?.image?.fluid} />
                  </div>
                )}
              </div>
              <div className="form">
                <InquiryForm
                  formspreeEndpoint={inquiry.formspreeEndpoint}
                  thanksPath={`/inquiry/${inquiry.contentful_id}/thanks`}
                />
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default Inquiry;

export const inquiryFormQuery = graphql`
  query Inquiry($contentfulId: String) {
    inquiry: contentfulInquiry(contentful_id: { eq: $contentfulId }) {
      contentful_id
      id
      formspreeEndpoint
      title
      thanks {
        contentful_id
      }
      body {
        id
        childMarkdownRemark {
          htmlAst
        }
      }
      image {
        id
        contentful_id
        fluid(maxWidth: 800) {
          base64
          aspectRatio
          src
          srcSet
          sizes
        }
      }
    }
  }
`;
